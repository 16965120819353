<template>
  <!--  <page-header-wrapper>-->
  <a-modal
      :visible="visible"
      width="80%"
      :footer="null"
      style="padding: 20px"
      @cancel="cancel">
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="24">
            <a-form-item label="用户名" prop="userId">
              <a-input v-model="queryParam.nickname" placeholder="请输入用户名" allow-clear/>
            </a-form-item>
          </a-col>

<!--          <a-col :md="5" :sm="24">-->
<!--            <a-form-item label="手机号" prop="userPhone">-->
<!--              <a-input v-model="queryParam.userPhone" placeholder="请输入手机号" allow-clear/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->


<!--          <a-col :md="5" :sm="24">-->
<!--            <a-form-item label="邮箱" prop="userEmail">-->
<!--              <a-input v-model="queryParam.userEmail" placeholder="请输入邮箱" allow-clear/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->


          <!--            <a-col :md="8" :sm="24">-->
          <!--              <a-form-item label="支付时间">-->
          <!--                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>-->
          <!--              </a-form-item>-->
          <!--            </a-col>-->
          <template v-if="advanced">
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="支付单号" prop="payNo">-->
            <!--                  <a-input v-model="queryParam.payNo" placeholder="请输入支付单号" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="三方订单号" prop="outTrandNo">-->
            <!--                  <a-input v-model="queryParam.outTrandNo" placeholder="请输入三方订单号" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="充值钻石数" prop="rechargeDiamond">-->
            <!--                  <a-input v-model="queryParam.rechargeDiamond" placeholder="请输入充值钻石数" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="支付金额" prop="payMoney">-->
            <!--                  <a-input v-model="queryParam.payMoney" placeholder="请输入支付金额" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="价格菜单id" prop="menuId">-->
            <!--                  <a-input v-model="queryParam.menuId" placeholder="请输入价格菜单id" allow-clear/>-->
            <!--&lt;!&ndash;                </a-form-item>&ndash;&gt;-->
            <!--&lt;!&ndash;              </a-col>&ndash;&gt;-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="订单号" prop="orderNo">-->
            <!--                  <a-input v-model="queryParam.orderNo" placeholder="请输入订单号" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="支付时间" prop="payTime">-->
            <!--                  <a-date-picker style="width: 100%" v-model="queryParam.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
          </template>
          <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
                <!--                  {{ advanced ? '收起' : '展开' }}-->
                <!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--                </a>-->
              </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 操作 -->
    <div class="table-operations">
      <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['recharge:order:add']">-->
      <!--          <a-icon type="plus" />新增-->
      <!--        </a-button>-->
      <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['recharge:order:edit']">-->
      <!--          <a-icon type="edit" />修改-->
      <!--        </a-button>-->
      <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['recharge:order:remove']">-->
      <!--          <a-icon type="delete" />删除-->
      <!--        </a-button>-->
      <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['recharge:order:export']">-->
      <!--          <a-icon type="download" />导出-->
      <!--        </a-button>-->
      <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
    </div>
    <!-- 增加修改 -->
    <create-form
        ref="createForm"
        @ok="getList"
    />
    <!-- 数据展示 -->
    <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :scroll="{x:'900px'}"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :rowKey="(record, index) => index + 1"
    >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
      <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      <span slot="operation" slot-scope="text, record">
        <a @click="$refs.DistributorIndex.getDatas(record.userId)" v-hasPermi="['user:distributor:edit']">
            <a-icon type="edit"/>用户分销明细
          </a>
          <a-divider type="vertical" v-hasPermi="['user:distributor:edit']"/>
           <a @click="$refs.DistributorUserIndex.getUserDatas(record.id)" v-hasPermi="['user:distributor:edit']">
            <a-icon type="search"/>二级分销商
          </a>
        </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
        style="   margin-top: 20px; display: flex;
    justify-content: flex-end;"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
    />
  </a-modal>
  <!--  </page-header-wrapper>-->
</template>

<script>
import {getUserDatas, listOrder, delOrder, getSecondUserDatas} from '@/api/recharge/order'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'SecondDistributorUserIndex',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      dateRange: [],
      // 高级搜索 展开/关闭
      advanced: false,
      userId: null,
      isT: null,
      userType: false,
      visible: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        orderNo: null,
        payNo: null,
        outTrandNo: null,
        rechargeDiamond: null,
        payMoney: null,
        menuId: null,
        payTime: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          width: 80,

          customRender: (text, record, index) => index + 1,
          align: 'center'
        },

        {
          title: '用户名',
          dataIndex: 'nickName',
          width: 120,
          align: 'center'
        },
        // {
        //   title: '邮箱',
        //   dataIndex: 'userEmail',
        //   width: 200,
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        //
        // {
        //   title: '手机号',
        //   dataIndex: 'userPhone',
        //   // ellipsis: true,,
        //   align: 'center'
        // },


        {
          title: '充值金额数($)',
          dataIndex: 'totalMoney',
          width: 120,
          align: 'center'
        },
        {
          title: '佣金收益数($)',
          dataIndex: 'getMoney',
          width: 120,
          align: 'center'
        },
      ]
    }
  },
  filters: {},
  // created() {
  //   this.getList()
  // },
  computed: {},
  watch: {},
  methods: {
    /** 查询钻石充值订单列表 */
    getList() {
      this.loading = true
      if (this.dateRange) {
        this.queryParam.begTime = this.dateRange[0]
        this.queryParam.endTime = this.dateRange[1]
      }

      this.queryParam.userId = this.userId
      getSecondUserDatas(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })

    },
    getUserDatas(userId) {
      this.visible = true
      this.userId = userId
      this.getList()
    },
    getSecondDatas(userId) {
      this.visible = true
      this.userId = userId
      this.userType = true
      this.getList()
    },
    cancel() {
      this.queryParam.distributorUserId = null
      this.visible = false
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.queryParam.distributorUserId = this.userId
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        orderNo: undefined,
        payNo: undefined,
        outTrandNo: undefined,
        rechargeDiamond: undefined,
        payMoney: undefined,
        menuId: undefined,
        payTime: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delOrder(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                    '删除成功',
                    3
                )
              })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('recharge/recharge-order/export', {
            ...that.queryParam
          }, `钻石充值订单_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
